import { isBlank } from '@/utils/validate.js'

export default {
	data() {
		return {
			showDetailStatusDialog: false,
			dialogTitle: '',
			openType: '',
			info: {},
			defaultTime: [], //数据展示-默认时间
		}
	},
	computed: {
		tenantType() {
			return this.$store.getters.userInfo.tenantType || '0'
		},

		pressureUnitName() {
			return this.$store.getters.pressureUnitName
		},
	},
	methods: {
		showDetailStatus(row) {
			this.defaultTime = []
			this.info = {
				name: '浓度',
				attributeKey: 'Density',
				unitName: '%LEL',
				deviceId: row.deviceId,
				createTime: row.createTime,
			}
			this.openType = 'alarm'
			this.dialogTitle = '浓度变化'
			// 告警已恢复则默认展示告警时间-告警恢复时间数据
			if (row.alarmStatus == 1) {
				this.defaultTime = [new Date(row.createTime), new Date(row.clearTime)]
			}
			this.$nextTick(() => {
				this.$refs.echartTable?.resetTime()
			})
			this.showDetailStatusDialog = true
		},
		showPressure(row) {
			this.info = {
				attributeKey: 'pressure',
				deviceId: row.deviceId,
				name: '压力',
				unitName: this.pressureUnitName,
				timeSeriesDesc: {
					drawType: ['list', 'lineChart'],
				},
				createTime: row.createTime,
			}
			this.openType = 'pressure'
			this.dialogTitle = '压力变化'
			this.$nextTick(() => {
				this.$refs.echartTable?.resetTime()
			})
			this.showDetailStatusDialog = true
		},
		// 告警信息or告警恢复时间
		getColumns(arr = []) {
			let result = [...arr]
			// 当前列表存在告警中记录-展示告警信息列
			if (this.tableData.find(item => item.alarmStatus == 0)) {
				result.splice(arr.length - 1, 0, {
					key: 'alarmDetail',
					name: '告警信息',
					render: (h, row) => {
						let alarmDetailArr = !isBlank(row.alarmDetail) ? row.alarmDetail.split('\n') : []
						let childList = []
						if (alarmDetailArr.length > 0) {
							alarmDetailArr.forEach(item => {
								childList.push(h('div', {}, item))
							})
						}
						return h('div', {}, !isBlank(row.alarmDetail) ? childList : '--')
					},
				})
			}
			// 当前列表存在告警中记录-展示告警恢复时间
			if (this.tableData.find(item => item.alarmStatus == 1)) {
				result.splice(arr.length - 1, 0, {
					key: 'clearTime',
					name: '告警恢复时间',
					width: '160',
				})
			}
			return result
		},
	},
}
