<template>
	<div class="page-layout" v-loading="loading">
		<gc-header headerData="alarmRecords">
			<template #ops>
				<el-button type="primary" v-show="form.alarmSeq === '0'" @click="handleExport">导出记录</el-button>
			</template>
		</gc-header>
		<div class="run-main">
			<div class="serach">
				<gc-custom-search
					v-for="item in searchCondition"
					:key="item.key"
					:keyWord="item.label"
					:width="item.width || '300px'"
					:search.sync="form[item.key]"
					:type="item.type"
					:searchOption="options[item.key]"
					:needAllForSearch="item.needAllForSearch"
					:datePickerType="item.type == 'date' ? item.datePickerType : 'daterange'"
					:filterable="item.filterable || false"
					:searchOptionKey="item.searchOptionKey"
					:required="item.required"
				></gc-custom-search>
				<div class="serach-ops">
					<el-button type="primary" @click="getList(1, apiGetAlarmRecordsList, 'alarm')" v-click-blur>
						查 询
					</el-button>
					<el-button @click="reset(apiGetAlarmRecordsList, 'alarm')" v-click-blur>重 置</el-button>
				</div>
				<gc-column-setting
					:storageKey="`localcolumn-alarm-records-${tenantType}`"
					:columns="columns"
					@update-column="data => (columns = data)"
				></gc-column-setting>
			</div>
			<div class="table-show">
				<gc-table
					:columns="columnList"
					:table-data="tableData"
					:border="true"
					:showPage="true"
					:total="page.total"
					:page-size="page.size"
					:currentPage="page.current"
					@current-page-change="
						currentPage => {
							currentPageChange(currentPage, apiGetAlarmRecordsList, 'alarm')
						}
					"
					@size-change="
						size => {
							page.size = size
							currentPageChange(1, apiGetAlarmRecordsList, 'alarm')
						}
					"
				></gc-table>
			</div>
		</div>
		<el-dialog :title="dialogTitle" :visible.sync="showDetailStatusDialog" width="45%">
			<EchartTable
				ref="echartTable"
				:open-type="openType"
				:info="info"
				:show="showDetailStatusDialog"
				:default-time="defaultTime"
			></EchartTable>
		</el-dialog>

		<gc-alarm-handle-dialog
			:show.sync="showHandleDialog"
			:alarmId="alarmId"
			@ops-success="getList(1, apiGetAlarmRecordsList, 'alarm')"
		></gc-alarm-handle-dialog>
	</div>
</template>

<script>
import { apiGetAlarmRecordsList } from '@/apis/alarm.api.js'
import common from '../mixins/common.js'
import deviceTypeOption from '../mixins/deviceTypeOption.js'
import { nameTrans } from '@/utils/index'
import { isBlank, isObject } from '@/utils/validate.js'
import EchartTable from '@/pages/device-manage/detail/components/ChartTable.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { exportReport } from '@/apis/statisticAnalysis.api'
import alarmMix from './mixin.js'
export default {
	name: 'alarmRecords',
	components: { EchartTable },
	mixins: [common, deviceTypeOption, alarmMix],
	data() {
		return {
			form: {
				alarmSeq: '0', //告警状态时序
				deviceNo: null, //设备编号
				firstCategory: null, //设备类别
				deviceTypeId: null, //设备类型
				alarmName: null, //告警名称
				alarmLevel: null, //告警等级
				time: null, //告警时间区间
				alarmTypeId: null,
				userType: null,
			}, //筛选条件
			options: {}, //下拉列表
			showHandleDialog: false,
			alarmId: '',
			currentAlarmSeq: '0',
			cancelRequest: true,
			columns: [],
		}
	},
	computed: {
		...mapGetters({
			needUpdateAlarmRecords: 'common/needUpdateAlarmRecords',
		}),
		// 告警状态时序
		alarmSeq() {
			return this.$store.getters.dataList.alarmSeq || []
		},
		// 告警等级
		alarmLevel() {
			return this.$store.getters.dataList.alarmLevel || []
		},
		// 告警状态
		alarmStatus() {
			return this.$store.getters.dataList.alarmStatus || []
		},
		// 告警处理结果
		alarmAckStatus() {
			return this.$store.getters.dataList.alarmAckStatus || []
		},
		// 告警分类
		alarmType() {
			return this.$store.getters.dataList.alarmType || []
		},
		tenantType() {
			return this.$store.getters.userInfo.tenantType || '0'
		},
		searchCondition() {
			if (this.tenantType == 1) {
				return [
					{
						key: 'alarmSeq',
						label: '告警状态时序',
						type: 'select',
						searchOptionKey: {
							label: 'name',
							value: 'defaultValue',
						},
						required: true,
					},
					{
						key: 'deviceNo',
						label: '设备编号',
					},
					{
						key: 'alarmName',
						label: '告警名称',
						needAllForSearch: true,
					},
					{
						key: 'alarmTypeId',
						label: '告警分类',
						type: 'select',
						needAllForSearch: true,
						searchOptionKey: {
							label: 'name',
							value: 'defaultValue',
						},
					},
					{
						key: 'userType',
						label: '客户类型',
						type: 'select',
						needAllForSearch: true,
						searchOptionKey: {
							label: 'name',
							value: 'defaultValue',
						},
					},
					{
						key: 'alarmLevel',
						label: '告警等级',
						type: 'select',
						needAllForSearch: true,
						searchOptionKey: {
							label: 'name',
							value: 'defaultValue',
						},
					},
					{
						key: 'time',
						label: '告警时间区间',
						type: 'date',
						datePickerType: 'datetimerange',
						width: '620px',
					},
					{
						key: 'diffDay',
						label: '逾期未处理天数',
						type: 'input-number',
					},
				]
			} else {
				return [
					{
						key: 'alarmSeq',
						label: '告警状态时序',
						type: 'select',
						searchOptionKey: {
							label: 'name',
							value: 'defaultValue',
						},
						required: true,
					},
					{
						key: 'deviceNo',
						label: '设备编号',
					},
					{
						key: 'alarmName',
						label: '告警名称',
						needAllForSearch: true,
					},
					{
						key: 'alarmTypeId',
						label: '告警分类',
						type: 'select',
						needAllForSearch: true,
						searchOptionKey: {
							label: 'name',
							value: 'defaultValue',
						},
					},
					{
						key: 'firstCategory',
						label: '设备类别',
						type: 'select',
						needAllForSearch: true,
						searchOptionKey: {
							label: 'name',
							value: 'productCategoryId',
						},
					},
					{
						key: 'deviceTypeId',
						label: '设备类型',
						type: 'select',
						needAllForSearch: true,
						filterable: true,
						searchOptionKey: {
							label: 'deviceTypeName',
							value: 'deviceTypeId',
						},
					},
					{
						key: 'alarmLevel',
						label: '告警等级',
						type: 'select',
						needAllForSearch: true,
						searchOptionKey: {
							label: 'name',
							value: 'defaultValue',
						},
					},
					{
						key: 'time',
						label: '告警时间区间',
						type: 'date',
						datePickerType: 'datetimerange',
						width: '620px',
					},
					{
						key: 'diffDay',
						label: '逾期未处理天数',
						type: 'input-number',
					},
				]
			}
		},
		getDeviceTypeFlag() {
			return this.tenantType == 1
		},
		columnList() {
			return this.columns.filter(o => o.selected)
		},
	},
	mounted() {
		if (this.tenantType != 1) {
			this.getFirstCategory().then(data => {
				this.$set(this.options, 'firstCategory', data)
			})
		}
		if (this.$route.params.alarmType) {
			this.form.alarmTypeId = this.$route.params.alarmType
			this.form.firstCategory = this.$route.params.firstCategory
		}
		this.$set(this.options, 'alarmSeq', this.alarmSeq)
		this.$set(this.options, 'alarmLevel', this.alarmLevel)
		this.getList(1, apiGetAlarmRecordsList, 'alarm')
		this.$set(this.options, 'alarmTypeId', this.alarmType)
		this.$set(this.options, 'userType', this.$store.getters.dataList.userType || [])
	},
	watch: {
		'form.firstCategory': {
			handler() {
				this.form.deviceTypeId = null
				this.getDeviceTypeOptions()
			},
		},

		tenantType: {
			handler(newVal) {
				const columns = [
					{
						key: 'deviceNo',
						name: '设备编号',
						fixed: 'left',
						dragDisable: true,
						disabledSelect: true,
					},
					{
						key: 'deviceTypeName',
						name: '设备类型',
						tooltip: true,
					},
					{
						key: 'alarmType',
						name: '告警分类',
					},
					{
						key: 'alarmName',
						name: '告警名称',
					},
					{
						key: 'alarmLevel',
						name: '告警等级',
						render: (h, row) => {
							let alarmIcon = []
							if (!isBlank(row.alarmLevel)) {
								for (let i = 0; i < row.alarmLevel; i++) {
									alarmIcon.push(
										h('i', {
											class: 'iconfont icon-caution',
											style: {
												'margin-left': '6px',
												color: '#EC6B60',
												'font-size': '12px',
											},
										}),
									)
								}
							}
							return h('div', [
								h(
									'span',
									{
										style: {
											color: '#EC6B60',
										},
									},
									nameTrans(row.alarmLevel, this.alarmLevel),
								),
								...alarmIcon,
							])
						},
					},
					{
						key: 'alarmStatus',
						name: '告警状态',
						render: (h, row) => {
							return h('span', {}, [
								h('i', {
									class: row.alarmStatus == 0 ? 'dot-error' : 'dot-message',
								}),
								nameTrans(row.alarmStatus, this.alarmStatus),
							])
						},
					},
					{
						key: 'alarmAckStatus',
						name: '告警处理结果',
						render: (h, row) => {
							return h('span', {}, nameTrans(row.alarmAckStatus, this.alarmAckStatus))
						},
					},
					{
						key: 'createTime',
						name: '告警时间',
						width: '160',
					},
					{
						key: 'diffDay',
						name: '逾期未处理天数',
					},
					{
						key: 'userNo',
						name: '用户号',
					},
					{
						key: 'username',
						name: '客户/站点名称',
						tooltip: true,
					},
					{
						key: 'phone',
						name: '联系电话',
					},
					{
						key: 'address',
						name: '安装地址',
						tooltip: true,
					},
					{
						key: 'oprate',
						name: '操作',
						minWidth: 250,
						render: (h, row) => {
							//  自定义表格内容展示
							const childList = [
								row.isPressHigh === 1
									? h(
											'el-button',
											{
												props: {
													type: 'text',
													size: 'medium',
												},
												on: {
													click: () => this.showPressure(row),
												},
											},
											'压力查看',
									  )
									: '',
								this.currentAlarmSeq === '0' && this.$has('monitor:run:alarm-record:modify')
									? h(
											'el-button',
											{
												props: {
													type: 'text',
													size: 'medium',
												},
												on: {
													click: () => {
														this.handleAlarm(row)
													},
												},
											},
											'处理登记',
									  )
									: '',
								...[
									[39, 40, 55, 56, 57].includes(row.eventId)
										? h(
												'el-button',
												{
													props: {
														type: 'text',
														size: 'medium',
													},
													on: {
														click: () => this.showDetailStatus(row),
													},
												},
												'数据展示',
										  )
										: [],
								],
								this.$has('monitor:device:account:record:alarm')
									? h(
											'el-button',
											{
												props: {
													type: 'text',
													size: 'medium',
												},
												on: {
													click: () => {
														this.$router.push({
															name: 'deviceDetail',
															query: {
																deviceId: row.deviceId,
																deviceType: row.deviceTypeId,
															},
															params: {
																name: 'AlarmRecord',
															},
														})
													},
												},
											},
											'查看详情',
									  )
									: '',
							]
							const hasVNode = childList.some(item => isObject(item))
							return h('div', {}, hasVNode ? childList : '/')
						},
						fixed: 'right',
					},
				]
				if (newVal === '1') {
					columns.splice(1, 1, {
						key: 'userTypeName',
						name: '客户类型',
					})
				}
				this.columns = this.getColumns(columns)
			},
			immediate: true,
		},
	},
	methods: {
		...mapMutations({
			setUpdateAlarmRecords: 'common/SET_UPDATE_ALARM_RECORDS',
		}),
		...mapActions({
			getFirstCategory: 'common/getFirstCategory',
			getDeviceTypes: 'common/getAllDeviceTypeList',
		}),
		getDeviceTypeOptions() {
			this.getDeviceTypes({
				firstCategory: this.form.firstCategory || '',
			}).then(data => {
				this.$set(this.options, 'deviceTypeId', data)
			})
		},
		apiGetAlarmRecordsList,
		// 标记为已处理
		handleAlarm(row) {
			this.alarmId = row.alarmId
			this.showHandleDialog = true
		},
		handleExport() {
			if (!this.tableData.length) {
				this.$message.warning('暂无表格数据')
				return
			}
			this.$toast('下载中，请稍后...')
			const data = {
				method: 'post',
				url: '/monitor/run/alarm/export/excel',
				fileName: '告警记录.xlsx',
				data: {
					...this.form,
					alarmStartTime: this.form.time ? this.dayjs(this.form.time[0]).format('YYYY-MM-DD HH:mm:ss') : null,
					alarmEndTime: this.form.time ? this.dayjs(this.form.time[1]).format('YYYY-MM-DD HH:mm:ss') : null,
				},
			}
			exportReport(data, 'excel', '', this.$toast)
		},
	},
	activated() {
		if (this.$route.params?.update || this.needUpdateAlarmRecords) {
			this.setUpdateAlarmRecords(false)
			this.form = this.$options.data().form
			this.getList(1, apiGetAlarmRecordsList, 'alarm')
		}
		if (this.$route.params.alarmType) {
			this.form = this.$options.data().form
			this.form.alarmTypeId = this.$route.params.alarmType
			this.form.firstCategory = this.$route.params.firstCategory
			this.getList(1, apiGetAlarmRecordsList, 'alarm')
		}
	},
}
</script>
<style lang="scss" scoped>
@import '../runLayout.scss';
</style>
